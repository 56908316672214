module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"http://localhost:8000"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Captain Bob","short_name":"Lorem Ipsum","description":"Lorem ipsum dolor sit amet consectetur.","lang":"fr","display":"standalone","icon":"src/assets/images/logos/favicon.svg","icon_options":{"purpose":"any maskable"},"start_url":"/","background_color":"#ffffff","theme_color":"#000000","localize":[{"start_url":"/en/","lang":"en","name":"Captain Bob","short_name":"Lorem Ipsum","description":"Lorem ipsum dolor sit amet consectetur."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"05291ff5f90eed6bf7d2f8a8362e10b4"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
